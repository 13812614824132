import { Fragment, useState, useEffect } from "react";
import ReactModal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";

import CustomButton from "../../elements/customButton/customButton";
import CustomTextInput from "../../elements/customTextInput/customTextInput";
import DropDown from "../../elements/dropDown/dropDown";
import CategoryItem from "../../elements/categoryItem/categoryItem";

import categoryService from "../../services/categoryService";

const CategoriesScreen = () => {

    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(0);
    const [mode, setMode] = useState(0);

    const [name, setName] = useState("");
    const [selected, setSelected] = useState(0);
    const [type, setType] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState();

    const [categories, setCategories] = useState([]);
    const [briefCategories, setBriefCategories] = useState([]);

    const types = [
        {
            value: 0,
            title: "Dépense"
        },
        {
            value: 1,
            title: "Revenu"
        }
    ];

    const editCategory = (category) => {
        setModal(0);
        setMode(1);
        setName(category.name);
        setSelected(category.parentId);
        setType(category.type);
        setSelectedCategory(category);
        setOpen(true);
    };

    const removeCategory = (category) => {
        setSelectedCategory(category);
        setModal(1);
        setMode(0);
        setOpen(true);
    };

    const addACategory = () => {
        categoryService.AddCategoryToBudget({
            id: 0,
            budgetId: 0,
            parentId: selected,
            IsParent: false,
            name: name,
            type: type
        })
        .then((result) => {
            setCategories(result?? []);
            categoryService.GetAllParentlessByBudgetId()
            .then((briefCategories) => {
                setBriefCategories(briefCategories?? []);
                setOpen(false);
                categoryService.GetAllNotParentByBudgetId();
            });
        });
    };

    const updateACategory = () => {
        let categoryToUpdate = selectedCategory;
        categoryToUpdate.parentId = selected;
        categoryToUpdate.name = name;
        categoryToUpdate.type = type;
        categoryService.UpdateCategory(categoryToUpdate)
        .then((result) => {
            setCategories(result?? []);
            categoryService.GetAllParentlessByBudgetId()
            .then((briefCategories) => {
                setBriefCategories(briefCategories?? []);
                setOpen(false);
                categoryService.GetAllNotParentByBudgetId();
            });
        });
    };

    const archiveACategory = () => {
        categoryService.ArchiveCategory(selectedCategory.id)
        .then((result) => {
            setCategories(result?? []);
            categoryService.GetAllParentlessByBudgetId()
            .then((briefCategories) => {
                setBriefCategories(briefCategories?? []);
                setOpen(false);
                categoryService.GetAllNotParentByBudgetId();
            });
        });
    };

    useEffect(()=>{
        Promise.all([
            categoryService.GetAllByBudgetId(),
            categoryService.GetAllParentlessByBudgetId()
        ])
        .then(([categories, briefCategories]) => {
            setCategories(categories?? []);
            setBriefCategories(briefCategories?? []);
        })
        .catch(()=>{});
    },[]);

    return (
        <Fragment>
            <ReactModal
                isOpen={open}
                onRequestClose={()=>{setOpen(false)}}
                className="modal-base"
                overlayClassName="modal-overlay">
                <div>
                    <div className="modal-close">
                        <i onClick={()=>{setOpen(false)}}>
                            <FontAwesomeIcon icon={faXmark}/>
                        </i>
                    </div>
                    {
                        modal === 0 ?
                        <div className="category-modal">
                            <div>
                                {!selectedCategory || (selectedCategory && selectedCategory.isParent === false) ? <Fragment>
                                    <div className="category-modal-type">
                                        <p>Type</p>
                                        <DropDown
                                            options={types}
                                            selected={type}
                                            setSelected={setType}/>
                                    </div>
                                    <div className="category-modal-parent">
                                        <p>Catégorie parent</p>
                                        <DropDown
                                            options={briefCategories.filter(c => c.type === null || c.type === type).filter(c => selectedCategory ? c.value !== selectedCategory.id : c)}
                                            selected={selected}
                                            setSelected={setSelected}/>
                                    </div>
                                </Fragment> : <></>}
                                <CustomTextInput
                                    value={name}
                                    setValue={setName}
                                    placeholder="catégorie"
                                    type="text"/>
                            </div>
                            <CustomButton
                                onClick={()=>{mode === 0 ? addACategory() : updateACategory()}}
                                icon={mode === 0 ? faPlus : faSave}
                                title={mode === 0 ? "Ajouter" : "Enregistrer"}/>
                        </div> :
                        <div className="delete-income-modal">
                            <p>Supprimer ?</p>
                            <div>
                                <CustomButton
                                    onClick={()=>{setOpen(false)}}
                                    icon={faXmark}
                                    title={"Annuler"}/>
                                <CustomButton
                                    onClick={()=>{archiveACategory()}}
                                    icon={faTrash}
                                    title={"Oui"}
                                    remove={true}/>
                            </div>
                        </div>
                    }
                </div>
            </ReactModal>
            <div className="page-container">
                <div className="categories-container">
                    <CustomButton
                        onClick={()=>{
                            setModal(0);
                            setMode(0);
                            setName("");
                            setSelected(0);
                            setType(0);
                            setSelectedCategory();
                            setOpen(true);
                        }}
                        icon={faPlus}
                        title="Ajouter"/>
                    <div>
                        <p>Dépenses</p>
                        <div className="categories-list">
                            {categories
                            .filter(c => c.type === 0)
                            .map((category, key) => (
                                <Fragment key={key}>
                                    <CategoryItem
                                        data={category}
                                        even={key % 2 === 0}
                                        edit={()=>{editCategory(category);}}
                                        remove={()=>{removeCategory(category);}}/>
                                </Fragment>
                            ))}
                        </div>
                        <p>Revenus</p>
                        <div className="categories-list">
                            {categories
                            .filter(c => c.type === 1)
                            .map((category, key) => (
                                <Fragment key={key}>
                                    <CategoryItem
                                        data={category}
                                        even={key % 2 === 0}
                                        edit={()=>{editCategory(category);}}
                                        remove={()=>{removeCategory(category);}}/>
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CategoriesScreen;
import http from "../../functions/network";
import { store } from "../../functions/store";

const GetAllByBudgetId = () => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Get(`user/all/budget/${budgetId}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return [];
    });
};

const AddUserToBudget = (name) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Post(`user/budget/${budgetId}/${name}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return null;
    });
};

const UpdateUsername = (userId, name) => {
    return http.authorized.Put(`user/${userId}/username/${name}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return null;
    });
};

const ArchiveUser = (userId) => {
    return http.authorized.Delete(`user/${userId}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return null;
    });
};

export { GetAllByBudgetId, AddUserToBudget, UpdateUsername, ArchiveUser };
const OverviewProjectItem = ({data}) => {
    return( 
        <div className="overview-project-item">
            <p>{data.title}</p>
            <div className="project-progression-bar">
                <div style={{width: `${(data.percentage * 100)}%`}}></div>
            </div>
        </div>
    );
};

export default OverviewProjectItem;
import { useState, Fragment, useEffect } from "react";
import ReactModal from "react-modal";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark, faTrash, faSave, faCubesStacked } from "@fortawesome/free-solid-svg-icons";

import SubMenu from "../../elements/subMenu/subMenu";
import CustomButton from "../../elements/customButton/customButton";
import TimePeriodPicker from "../../elements/timePeriodPicker/timePeriodPicker";
import ExpenseItem from "../../elements/expenseItem/expenseItem";
import ExpenseCategory from "../../elements/expenseCategory/expenseCategory";
import CustomTextInput from "../../elements/customTextInput/customTextInput";
import CustomCheckbox from "../../elements/customCheckbox/customCheckbox";
import DropDown from "../../elements/dropDown/dropDown";

import { toMoney } from "../../functions/toMoney";
import dateSelectionService from "../../services/dateSelectionService";
import spendingService from "../../services/spendingService";

const SpendingsScreen = () => {

    const navigate = useNavigate();

    const [subPageIndex, setSubPageIndex] = useState(0);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth());
    const [years, setYears] = useState([{value: new Date().getFullYear(), title: ""}]);
    const [months, setMonths] = useState([{value: new Date().getMonth(), title: ""}]);

    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(0);
    const [mode, setMode] = useState(0);

    const [recursive, setRecursive] = useState(false);
    const [recursiveTime, setRecursiveTime] = useState(0);
    const [selected, setSelected] = useState(0);
    const [amount, setAmount] = useState(0);
    const [selectedSpending, setSelectedSpending] = useState();

    const [categories, setCategories] = useState([]);
    const [summary, setSummary] = useState({
        totalSpendings: 0,
        totalIncome: 0,
        balance: 0
    });
    const [entries, setEntries] = useState([]);

    const spendingOptions = useSelector((state) => state.categories).filter(c => c.type === 0);

    const recursiveTimes = [
        {
            title: "Quotidien",
            value: 0
        },
        {
            title: "Hebdomadaire",
            value: 1
        },
        {
            title: "Mensuel",
            value: 2
        },
        {
            title: "Annuel",
            value: 3
        }
    ];

    const GetSummary = () => {
        spendingService.GetSummaryByMonth(new Date(year, month - 1, 1).toISOString())
        .then((result) => {
            const summaries = result.slice(-3);
            setSummary({
                totalSpendings: summaries[0]?.total ?? 0,
                totalIncome: summaries[1]?.total ?? 0,
                balance: summaries[2]?.total ?? 0
            });
            setCategories(result.slice(0,-3));
        });
    };

    const GetSpendingList = () => {
        spendingService.GetSpendingListByMonth(new Date(year, month - 1, 1).toISOString())
        .then((result) => {
            setEntries(result);
        });
    };

    const GetSpendingInfos = () => {
        GetSummary();
        GetSpendingList();
    };

    const AddSpending = () => {
        spendingService.AddSpending({
            id: 0,
            userId: 0,
            budgetId: 0,
            categoryId: selected,
            amount: amount,
            date: new Date(),
            recursive: recursive,
            recursiveType: recursiveTime
        })
        .then((success) => {
            if (success){
                GetSpendingInfos();
            }
            setOpen(false);
        });
    };

    const UpdateSpending = () => {
        spendingService.UpdateSpending({
            id: selectedSpending.id,
            userId: selectedSpending.userId,
            budgetId: 0,
            categoryId: selected,
            amount: amount,
            date: selectedSpending.date,
            recursive: recursive,
            recursiveType: recursiveTime
        })
        .then((success) => {
            if (success){
                GetSpendingInfos();
            }
            setOpen(false);
        });
    };

    const RemoveSpending = () => {
        spendingService.RemoveSpending(selectedSpending.id)
        .then((success) => {
            if (success){
                GetSpendingInfos();
            }
            setOpen(false);
        });
    };

    useEffect(()=>{
        dateSelectionService.GetYears()
        .then((result) => {
            setYears(result);
            setYear(new Date().getFullYear());
        });
    },[]);

    useEffect(()=>{
        dateSelectionService.GetMonths(year)
        .then((result) => {
            setMonths(result);
            setMonth(new Date().getMonth() + 1);
        });
    },[year]);

    useEffect(()=>{
        GetSpendingInfos();
    },[month, year]);

    useEffect(()=>{
        if (selected === 0){
            setSelected(spendingOptions[0]?.value ?? 0);
        }
    },[spendingOptions]);

    return (
        <Fragment>
            <ReactModal
                isOpen={open}
                onRequestClose={()=>{setOpen(false)}}
                className="modal-base"
                overlayClassName="modal-overlay">
                <div>
                    <div className="modal-close">
                        <i onClick={()=>{setOpen(false)}}>
                            <FontAwesomeIcon icon={faXmark}/>
                        </i>
                    </div>
                    {
                        modal === 0 ?
                        <div className="spending-modal">
                            <div>
                                <DropDown
                                    options={spendingOptions}
                                    selected={selected}
                                    setSelected={setSelected}/>
                                <div className="income-modal-amount">
                                    <CustomTextInput
                                        value={amount}
                                        setValue={setAmount}
                                        type="number"/>
                                    <p>$</p>
                                </div>
                            </div>
                            <div>
                                <CustomCheckbox
                                    title="Récurrent"
                                    checked={recursive}
                                    setChecked={setRecursive}/>
                                {recursive &&
                                    <DropDown
                                        options={recursiveTimes}
                                        selected={recursiveTime}
                                        setSelected={setRecursiveTime}/>}
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <CustomButton
                                    onClick={()=>{mode === 0 ? AddSpending() : UpdateSpending()}}
                                    icon={mode === 0 ? faPlus : faSave}
                                    title={mode === 0 ? "Ajouter" : "Enregistrer"}/>
                                <div style={{marginLeft: '10px'}}>
                                    <CustomButton
                                        onClick={()=>{navigate("/categories", { replace: true })}}
                                        icon={faCubesStacked}
                                        title="Gérer les catégories"/>
                                </div>
                            </div>
                        </div>:
                        <div className="delete-spending-modal">
                            <p>Supprimer ?</p>
                            <div>
                                <CustomButton
                                    onClick={()=>{setOpen(false)}}
                                    icon={faXmark}
                                    title={"Annuler"}/>
                                <CustomButton
                                    onClick={()=>{RemoveSpending()}}
                                    icon={faTrash}
                                    title={"Oui"}
                                    remove={true}/>
                            </div>
                        </div>
                    }
                </div>
            </ReactModal>
            <div className="page-container">
                <div className="spendings-header">
                    <CustomButton
                        title="Ajouter"
                        icon={faPlus}
                        onClick={()=>{
                            setModal(0);
                            setMode(0);
                            setAmount(0);
                            setSelected(0);
                            setRecursive(false);
                            setRecursiveTime(0);
                            setOpen(true);
                        }}/>
                    <TimePeriodPicker
                        options={{years: years, months: months}}
                        year={year}
                        setYear={setYear}
                        month={month}
                        setMonth={setMonth}/>
                </div>
                <SubMenu options={[
                    {
                        title: "Catégories",
                        value: 0
                    },
                    {
                        title: "Liste",
                        value: 1
                    }
                ]}
                selected={subPageIndex}
                setSelected={setSubPageIndex}/>
                <div>
                    {subPageIndex === 0 ?
                    <div>
                        <div className="spending-categories-list">
                            {categories.map((entry, key) => (
                                <Fragment key={key}>
                                    <ExpenseCategory
                                        even={key % 2 === 0}
                                        data={entry}
                                    />
                                </Fragment>
                            ))}
                        </div>
                        <div className="spending-categories-list">
                            <div className="spending-total"
                                style={
                                    {
                                        backgroundColor: '#070707',
                                        color: 'white'
                                    }}>
                                <p>Total</p>
                                <p>{toMoney(summary.totalSpendings)}</p>
                            </div>
                            <div className="spending-total"
                                style={{backgroundColor: '#eee'}}>
                                <p>Total revenus</p>
                                <p>{toMoney(summary.totalIncome)}</p>
                            </div>
                            <div className="spending-total"
                                style={
                                    {
                                        backgroundColor: summary.balance < 0 ?
                                        '#f00' : '#3be219',
                                        color: 'white'}}>
                                <p>Balance</p>
                                <p>{toMoney(summary.balance)}</p>
                            </div>
                        </div>
                    </div> : 
                    <div className="spending-entries-list">
                        {entries.map((entry, key) => (
                        <Fragment key={key}>
                            <ExpenseItem
                            even={key % 2 === 0}
                            data={entry}
                            edit={()=>{
                                setModal(0);
                                setMode(1);
                                setSelectedSpending(entry);
                                setAmount(entry.price);
                                setSelected(entry.categoryId);
                                setRecursive(entry.recursive);
                                setRecursiveTime(entry.recursiveType);
                                setOpen(true);
                            }}
                            remove={()=>{
                                setModal(1);
                                setSelectedSpending(entry);
                                setOpen(true);
                            }}/>
                        </Fragment>
                        ))}
                    </div>}
                </div>
            </div>
        </Fragment>
    );
};

export default SpendingsScreen;
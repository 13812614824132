import { faPen, faXmark } from "@fortawesome/free-solid-svg-icons";

import SmallButton from "../smallButton/smallButton";

import { toMoney } from "../../functions/toMoney";

const ExpenseItem = ({even, data, edit, remove}) => {
    return (
        <div
            className="expense-item"
            style={{backgroundColor: even ? '#eee' : '#ddd'}}>
            <p>{data.category}</p>
            <p>{toMoney(data.price)}</p>
            <p>{data.user}</p>
            <p>{new Date(data.date).toLocaleDateString()}</p>
            <div className="expense-item-actions">
                <SmallButton onClick={edit} icon={faPen}/>
                <SmallButton onClick={remove} icon={faXmark} remove={true}/>
            </div>
        </div>
    );
};

export default ExpenseItem;
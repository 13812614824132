import { createSlice } from "@reduxjs/toolkit";

const initialState = [{value: 0, type: 0, title: ""}, {value: 0, type: 1, title: ""}];

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState: initialState,
    reducers: {
        setCategories: (state, action) => {
            return action.payload;
        }
    }
});

export const {
    setCategories
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
import { faPen, faXmark } from "@fortawesome/free-solid-svg-icons";

import SmallButton from "../smallButton/smallButton";

import { toMoney } from "../../functions/toMoney";

const ProjectItem = ({data, edit, remove}) => {

    return(
        <div className="project-item">
            <div>
                <p>{data.title}</p>
                <p>{toMoney(data.total)} / {toMoney(data.objective)}</p>
                <div>
                    <SmallButton
                        icon={faPen}
                        onClick={edit}/>
                    <SmallButton
                        icon={faXmark}
                        onClick={remove}
                        remove={true}/>
                </div>
            </div>
            <div className="project-progression-bar">
                <div style={{width: `${((data.total / data.objective) * 100)}%`}}>
                    </div>
            </div>
        </div>
    );
};

export default ProjectItem;
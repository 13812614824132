const SubMenu = ({options, selected, setSelected}) => {
    return (
        <div className="sub-menu">
            {options.map((entry, key) => (
                <div
                    className={`sub-menu-button ${selected === entry.value && "sub-menu-button-selected"}`}
                    key={key}
                    onClick={()=>{setSelected(entry.value)}}>
                    <p>{entry.title}</p>
                    <div></div>
                </div>
            ))}
        </div>
    );
};

export default SubMenu;
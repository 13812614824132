import http from "../../functions/network";
import { store } from "../../functions/store";

const GetYears = () => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Get(`date-selection/budget/${budgetId}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return [];
    });
};

const GetMonths = (year) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Get(`date-selection/budget/${budgetId}/year/${year}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return [];
    });
};

export { GetYears, GetMonths };
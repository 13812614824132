const CustomTextInput = ({value, setValue, type="text", placeholder=""}) => {

    const filterNumber = ((value)=> {
        let numValue = value.replace(',','.').replace(/[^\d.]/g,'');
        const dotCount = (numValue.match(/[.]/g) || []).length;
        if (dotCount <= 1){
            if (!isNaN(numValue.slice(-1))){
                numValue = parseFloat(numValue);
            }
        }
        else{
            numValue = numValue.slice(0, -1);
        }
        setValue(isNaN(numValue) ? 0 : numValue);
    });

    return(
        <input
            className="custom-text-input"
            placeholder={placeholder}
            type={type === "number" ? "text" : type}
            value={value}
            pattern={type === "number" && "[0-9]*"}
            inputMode={type === "number" && "decimal"}
            onChange={(e)=>{type === "number" ? filterNumber(e.target.value) : setValue(e.target.value)}}>
        </input>
    );
};

export default CustomTextInput;
import { Fragment, useState, useEffect } from "react";

import { toMoney } from "../../functions/toMoney";
import OverviewProjectItem from "../../elements/overviewProjectItem/overviewProjectItem";

import overviewService from "../../services/overviewService";

const OverViewScreen = () => {

    const [overview, setOverview] = useState({
        totalSpending: 0,
        totalIncome: 0,
        balance: 0,
        projects: []
    });

    useEffect(()=>{
        overviewService.GetOverview()
        .then((result) => {
            if (result !== null){
                setOverview(result);
            }
        });
    },[]);

    return (
        <div className="page-container">
            <div className="overview-summary">
                <div>
                    <p>Total dépenses</p>
                    <p>{toMoney(overview.totalSpending)}</p>
                </div>
                <div>
                    <p>Total Revenus</p>
                    <p>{toMoney(overview.totalIncome)}</p>
                </div>
                <div>
                    <p>Balance</p>
                    <p>{toMoney(overview.balance)}</p>
                </div>
            </div>
            <div className="overview-project-list">
                {overview.projects.map((project, key) => (
                    <Fragment key={key}>
                        <OverviewProjectItem
                            data={project}/>
                    </Fragment>
                ))}
            </div>
        </div>
    );
};

export default OverViewScreen;
import { useNavigate } from "react-router-dom";

const NavBar = () => {

    const navigate = useNavigate();

    const currentPage = () => {
        if (window.location.pathname.includes("depenses"))
            return 1;
        else if (window.location.pathname.includes("revenus"))
            return 2;
        else if (window.location.pathname.includes("projets"))
            return 3;
        else if (window.location.pathname.includes("categories"))
            return 4;
        else
            return 0;
    }

    return(
        <div className="navbar-container">
            <div className="navbar-subcontainer">
                <div className="navbar-logo"></div>
                <NavButton
                    title="Résumé"
                    onClick={()=>{navigate("overview", { replace: true })}}
                    current={currentPage() === 0}/>
                <NavButton
                    title="Dépenses"
                    onClick={()=>{navigate("depenses", { replace: true })}}
                    current={currentPage() === 1}/>
                <NavButton
                    title="Revenus"
                    onClick={()=>{navigate("revenus", { replace: true })}}
                    current={currentPage() === 2}/>
                <NavButton
                    title="Projets"
                    onClick={()=>{navigate("projets", { replace: true })}}
                    current={currentPage() === 3}/>
            </div>
        </div>
    );
};

const NavButton = ({title, onClick, current}) => {
    return(
        <div
            onClick={onClick}
            className={`navbutton ${current && "navbutton-current"}`}>
            <p>{title}</p>
            <div></div>
        </div>
    );
}

export default NavBar;
import { faPen, faXmark } from "@fortawesome/free-solid-svg-icons";

import SmallButton from "../smallButton/smallButton";

const ContributorItem = ({data, even, edit, remove}) => {
    return(
        <div
            className="contributor-item"
            style={{backgroundColor: even ? '#eee' : '#ddd'}}>
            <p>{data}</p>
            <div className="contributor-item-actions">
                <SmallButton onClick={edit} icon={faPen}/>
                <SmallButton onClick={remove} icon={faXmark} remove={true}/>
            </div>
        </div>
    );
};

export default ContributorItem;
import { store } from "../../functions/store";
import { setToken, setUserId, setBudgetId } from "../../functions/store/reducers/auth";
import storage from "../../functions/storage";

import http from "../../functions/network";

const SignIn = (username, password) => {
    return http.Post("user/sign-in", {
        username: username,
        password: password
    }, {'Content-Type' : 'application/json',})
    .then((result) => {
        if (!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        store.dispatch(setToken(data.token));
        store.dispatch(setUserId(data.userId));
        store.dispatch(setBudgetId(data.budgetId));

        storage.save("bbui", data.userId);
        storage.save("bbbi", data.budgetId);
        storage.save("bbto", data.bbto);
        storage.save("bbtt", data.bbtt);

        return true;
    })
    .catch((error) => {
        return false;
    });
};

const VerifyToken = () => {
    const userId = storage.get("bbui");
    const budgetId = storage.get("bbbi");
    const bbto = storage.get("bbto");
    const bbtt = storage.get("bbtt");

    return http.Post("user/verify-token", {
        userId: userId,
        budgetId: budgetId,
        token: "",
        bbto: bbto,
        bbtt: bbtt
    }, {'Content-Type' : 'application/json',})
    .then((result) => {
        if (!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        store.dispatch(setToken(data.token));
        store.dispatch(setUserId(data.userId));
        store.dispatch(setBudgetId(data.budgetId));
    })
    .catch((error) => {
        store.dispatch(setToken(""));
    });
};

export { SignIn, VerifyToken };
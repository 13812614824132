import BaseURL from "../../baseURL";

const Get = (uri, body=undefined, headers=undefined) => {
    return request(uri, "GET", body, headers);
};

const Post = (uri, body=undefined, headers=undefined) => {
    return request(uri, "POST", body, headers);
};

const Put = (uri, body=undefined, headers=undefined) => {
    return request(uri, "PUT", body, headers);
};

const Delete = (uri, body=undefined, headers=undefined) => {
    return request(uri, "DELETE", body, headers);
};

const request = (uri, method, body, headers) => {
    return fetch(`${BaseURL()}/${uri}`, {
        method: method,
        body: body ? JSON.stringify(body) : undefined,
        headers: headers ? headers : {}
    });
};

export { Get, Post, Put, Delete };
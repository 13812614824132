import SmallButton from "../smallButton/smallButton";

import { faPen, faXmark } from "@fortawesome/free-solid-svg-icons";

const CategoryItem = ({data, even, edit, remove}) => {
    return(
        <div 
            className="category-item"
            style={{
                backgroundColor: data.isParent ? '#070707' : even ? '#eee' : '#ddd',
                color: data.isParent ? 'white' : 'black'
            }}>
            <p>{data.name}</p>
            <div>
                <SmallButton
                    icon={faPen}
                    onClick={edit}/>
                {!data.isParent &&
                    <SmallButton
                        icon={faXmark}
                        onClick={remove}
                        remove={true}/>}
            </div>
        </div>
    );
};

export default CategoryItem;
import http from "../../functions/network";
import { store } from "../../functions/store";

const GetSummaryByMonth = (date) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Get(`money-entry/summary/budget/${budgetId}/${date}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return [];
    });
};

const GetSpendingListByMonth = (date) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Get(`money-entry/spending/budget/${budgetId}/${date}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return [];
    });
};

const AddSpending = (newSpending) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    const userId = state.auth.userId;
    let spendingToSend = newSpending;
    spendingToSend.budgetId = budgetId;
    spendingToSend.userId = userId;
    return http.authorized.Post(`money-entry/spending`, spendingToSend,
        {'Content-Type' : 'application/json',})
        .then((result) => {
            if(!result.ok){
                throw new Error();
            }
            return true;
        })
        .catch((error) => {
            return false;
        });
};

const UpdateSpending = (updatedSpending) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    let spendingToSend = updatedSpending;
    spendingToSend.budgetId = budgetId;
    return http.authorized.Put(`money-entry/spending`, spendingToSend,
        {'Content-Type' : 'application/json',})
        .then((result) => {
            if(!result.ok){
                throw new Error();
            }
            return true;
        })
        .catch((error) => {
            return false;
        });
};

const RemoveSpending = (id) => {
    return http.authorized.Delete(`money-entry/${id}`)
        .then((result) => {
            if(!result.ok){
                throw new Error();
            }
            return true;
        })
        .catch((error) => {
            return false;
        });
};

export { GetSummaryByMonth, GetSpendingListByMonth, AddSpending, UpdateSpending, RemoveSpending };
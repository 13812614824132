import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomButton = ({title, icon=undefined, onClick, remove=false}) => {
    return (
        <div className={`custom-button ${remove && "custom-button-remove"}`}
             onClick={onClick}>
            {icon && <i><FontAwesomeIcon icon={icon}/></i>}
            <p>{title}</p>
        </div>
    );
};

export default CustomButton;
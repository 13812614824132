import { useState, useEffect, Fragment } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUserPlus, faXmark, faSave, faTrash, faCubesStacked } from "@fortawesome/free-solid-svg-icons";

import SubMenu from "../../elements/subMenu/subMenu";
import CustomButton from "../../elements/customButton/customButton";
import TimePeriodPicker from "../../elements/timePeriodPicker/timePeriodPicker";
import ContributorItem from "../../elements/contributorItem/contributorItem";
import IncomeItem from "../../elements/incomeItem/incomeItem";
import CustomTextInput from "../../elements/customTextInput/customTextInput";
import DropDown from "../../elements/dropDown/dropDown";

import userService from "../../services/userService";
import dateSelectionService from "../../services/dateSelectionService";
import incomeService from "../../services/incomeService";

const IncomeScreen = () => {

    const navigate = useNavigate();

    const [subPageIndex, setSubPageIndex] = useState(0);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth());
    const [years, setYears] = useState([{value: new Date().getFullYear(), title: ""}]);
    const [months, setMonths] = useState([{value: new Date().getMonth(), title: ""}]);

    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(0);
    const [mode, setMode] = useState(0);

    const [name, setName] = useState("");
    const [selected, setSelected] = useState(0);
    const [amount, setAmount] = useState(0);
    const [selectedIncome, setSelectedIncome] = useState();


    const [contributors, setContributors] = useState([]);
    const [selectedContributor, setSelectedContributor] = useState(0);

    const [incomes, setIncomes] = useState([]);

    let incomeOptions = useSelector((state) => state.categories).filter(c => c.type === 1);

    const addUser = (username) => {
        userService.AddUserToBudget(username)
        .then((result) => {
            if (result !== null)
                setContributors(result);
            setOpen(false);
        });
    };

    const updateUsername = (username) => {
        userService.UpdateUsername(selectedContributor, username)
        .then((result) => {
            if (result !== null)
                setContributors(result);
            setOpen(false);
        });
    };

    const archiveUser = () => {
        userService.ArchiveUser(selectedContributor)
        .then((result) => {
            if (result !== null)
                setContributors(result);
            setOpen(false);
        });
    };

    const getIncomes = () => {
        incomeService.GetAllByBudgetIdAndMonth(new Date(year, month - 1 , 1).toISOString())
        .then((result) => {
            setIncomes(result);
        });
    }

    const addIncome = () => {
        incomeService.AddIncomeToBudget({
            id: 0,
            budgetId: 0,
            userId: 0,
            categoryId: selected,
            amount: amount,
            date: new Date().toISOString()
        })
        .then((success) => {
            if (success){
                getIncomes();
            }
            setOpen(false);
        });
    };

    const updateIncome = () => {
        incomeService.UpdateIncomeOfBudget({
            id: selectedIncome.id,
            budgetId: 0,
            userId: selectedIncome.userId,
            categoryId: selected,
            amount: amount,
            date: selectedIncome.date
        })
        .then((success) => {
            if (success){
                getIncomes();
            }
            setOpen(false);
        });
    };

    const removeIncome = () => {
        incomeService.RemoveIncomeFromBudget(selectedIncome.id)
        .then((success) => {
            if (success){
                getIncomes();
            }
            setOpen(false);
        });
    };

    useEffect(()=>{
        userService.GetAllByBudgetId()
        .then((result) => {
            setContributors(result);
        });
        dateSelectionService.GetYears()
        .then((result) => {
            setYears(result);
            setYear(new Date().getFullYear());
        });
    },[]);

    useEffect(()=>{
        dateSelectionService.GetMonths(year)
        .then((result) => {
            setMonths(result);
            setMonth(new Date().getMonth() + 1);
        });
    }, [year]);

    useEffect(()=>{
        getIncomes();
    }, [month, year]);

    useEffect(()=>{
        if (selected === 0){
            setSelected(incomeOptions[0]?.value ?? 0);
        }
    },[incomeOptions]);

    return (
        <Fragment>
            <ReactModal
                isOpen={open}
                onRequestClose={()=>{setOpen(false)}}
                className="modal-base"
                overlayClassName="modal-overlay">
                <div>
                    <div className="modal-close">
                        <i onClick={()=>{setOpen(false)}}>
                            <FontAwesomeIcon icon={faXmark}/>
                        </i>
                    </div>
                    {
                        modal === 0 ?
                        <div className="income-modal">
                            <div>
                                <DropDown
                                    options={incomeOptions}
                                    selected={selected}
                                    setSelected={setSelected}/>
                                <div className="income-modal-amount">
                                    <CustomTextInput
                                        value={amount}
                                        setValue={setAmount}
                                        type="number"/>
                                    <p>$</p>
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <CustomButton
                                    onClick={()=>{mode === 0 ? addIncome() : updateIncome()}}
                                    icon={mode === 0 ? faPlus : faSave}
                                    title={mode === 0 ? "Ajouter" : "Enregistrer"}/>
                                <div style={{marginLeft: '10px'}}>
                                    <CustomButton
                                        onClick={()=>{navigate("/categories", { replace: true })}}
                                        icon={faCubesStacked}
                                        title="Gérer les catégories"/>
                                </div>
                            </div>
                        </div> : modal === 1 ?
                        <div className="contributor-modal">
                            <CustomTextInput
                                value={name}
                                setValue={setName}
                                placeholder="nom du contributeur"/>
                            <CustomButton
                                onClick={()=>{mode === 0 ? addUser(name) : updateUsername(name)}}
                                icon={mode === 0 ? faUserPlus : faSave}
                                title={mode === 0 ? "Ajouter" : "Enregistrer"}/>
                        </div> :
                        <div className="delete-income-modal">
                            <p>Supprimer ?</p>
                            <div>
                                <CustomButton
                                    onClick={()=>{setOpen(false)}}
                                    icon={faXmark}
                                    title={"Annuler"}/>
                                <CustomButton
                                    onClick={()=>{modal === 2 ? removeIncome() : archiveUser()}}
                                    icon={faTrash}
                                    title={"Oui"}
                                    remove={true}/>
                            </div>
                        </div>
                    }
                </div>
            </ReactModal>
            <div className="page-container">
                <SubMenu options={[
                    {
                        title: "Liste",
                        value: 0
                    },
                    {
                        title: "Contributeurs",
                        value: 1
                    }
                ]}
                selected={subPageIndex}
                setSelected={setSubPageIndex}/>
                <div>
                    {subPageIndex === 0 ?
                    <div>
                        <div className="spendings-header">
                            <CustomButton
                                title="Ajouter"
                                icon={faPlus}
                                onClick={()=>{
                                    setModal(0);
                                    setMode(0);
                                    setSelected(0);
                                    setAmount(0);
                                    setOpen(true);
                                }}/>
                            <TimePeriodPicker
                                options={{years: years, months: months}}
                                year={year}
                                setYear={setYear}
                                month={month}
                                setMonth={setMonth}/>
                        </div>
                        <div className="income-lists-container">
                            {incomes.map((i, key) => (
                                <Fragment key={key}>
                                    <div className="income-list">
                                        <p>{i.name}</p>
                                        <div>
                                            {i.income.map((inc, k) => (
                                                <Fragment key={k}>
                                                    <IncomeItem
                                                        data={inc}
                                                        even={k % 2 === 0}
                                                        edit={()=>{
                                                            setModal(0);
                                                            setMode(1);
                                                            setSelectedIncome(inc);
                                                            setSelected(inc.categoryId);
                                                            setAmount(inc.price);
                                                            setOpen(true);
                                                        }}
                                                        remove={()=>{
                                                            setSelectedIncome(inc);
                                                            setModal(2);
                                                            setOpen(true);
                                                        }}/>
                                                </Fragment>
                                            ))}
                                        </div>
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    </div>:
                    <div className="income-contributors-container">
                        <CustomButton
                        title="Ajouter"
                        icon={faUserPlus}
                        onClick={()=>
                            {
                                setModal(1);
                                setMode(0);
                                setName("");
                                setOpen(true);
                            }}/>
                        <div>
                            {contributors.map((i, key) => (
                            <Fragment key={key}>
                                <ContributorItem
                                even={key % 2 === 0}
                                data={i.username}
                                edit={()=>{
                                    setModal(1);
                                    setMode(1);
                                    setName(i.username);
                                    setSelectedContributor(i.id);
                                    setOpen(true);
                                }}
                                remove={()=>{
                                    setModal(3);
                                    setSelectedContributor(i.id);
                                    setOpen(true);
                                }}/>
                            </Fragment>
                            ))}
                        </div>
                    </div>}
                </div>
            </div>
        </Fragment>
    );
};

export default IncomeScreen;
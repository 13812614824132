import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import OverViewScreen from "./screens/overview/overview";
import SpendingsScreen from "./screens/spendings/spendings";
import IncomeScreen from "./screens/income/income";
import ProjectsScreen from "./screens/projects/projects";
import CategoriesScreen from "./screens/categories/categories";
import LoginScreen from "./screens/login/login";

import NavBar from "./elements/navigation/navigation";

import authService from "./services/authService";
import categoryService from "./services/categoryService";

function App() {

  let token = useSelector((state) => state.auth.token);

  useEffect(()=>{
    authService.VerifyToken();
  },[]);

  useEffect(()=>{
    if (token !== ""){
      categoryService.GetAllNotParentByBudgetId();
    }
  },[token]);

  return (
      <BrowserRouter>
        {token !== "" && <NavBar/>}
        <Routes>
          <Route index element={token === "" ? <LoginScreen/> : <Navigate to={"/overview"} replace={true}/>}/>
          <Route path="overview" element={token !== "" ? <OverViewScreen/> : <Navigate to={"/"} replace={true}/>}/>
          <Route path="depenses" element={token !== "" ? <SpendingsScreen/> : <Navigate to={"/"} replace={true}/>}/>
          <Route path="revenus" element={token !== "" ? <IncomeScreen/> : <Navigate to={"/"} replace={true}/>}/>
          <Route path="projets" element={token !== "" ? <ProjectsScreen/> : <Navigate to={"/"} replace={true}/>}/>
          <Route path="categories" element={token !== "" ? <CategoriesScreen/> : <Navigate to={"/"} replace={true}/>}/>
        </Routes>
      </BrowserRouter>
  );
}

export default App;

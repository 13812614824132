import { useState } from "react";
import { useNavigate } from "react-router-dom";

import CustomButton from "../../elements/customButton/customButton";
import CustomTextInput from "../../elements/customTextInput/customTextInput";

import authService from "../../services/authService";

const LoginScreen = () => {

    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);

    const loginAttempt = () => {
        if (username === "" || password === ""){
            setError(true);
            return;
        }
        authService.SignIn(username, password)
        .then((result) => {
            if (result === true){
                setError(false);
                navigate("/overview", { replace: true });
            }
            else{
                setError(true);
                setPassword("");
            }
        });
    };

    return(
        <div className="login-page">
            <div>
                <div className="login-logo"></div>
                <div className="login-controls">
                    <CustomTextInput
                        value={username}
                        setValue={setUsername}
                        placeholder="Nom d'utilisateur"/>
                    <CustomTextInput
                        type="password"
                        value={password}
                        setValue={setPassword}
                        placeholder="Mot de passe"/>
                    <div style={{height: '10px'}}>
                        {error &&
                            <p style={{color: '#f22', margin: '0px'}}>Une erreur est survenue</p>}
                    </div>
                    <CustomButton
                        title="Connexion"
                        onClick={()=>{loginAttempt();}}/>
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
import { faPen, faXmark } from "@fortawesome/free-solid-svg-icons";

import SmallButton from "../smallButton/smallButton";

import { toMoney } from "../../functions/toMoney";

const IncomeItem = ({data, even, edit, remove}) => {
    return(
        <div
            className="income-item"
            style={{backgroundColor: even ? '#eee' : '#ddd'}}>
            <p>{data.source}</p>
            <p>{toMoney(data.price)}</p>
            <p>{new Date(data.date).toLocaleDateString()}</p>
            <div className="income-item-actions">
                <SmallButton
                    icon={faPen}
                    onClick={edit}/>
                <SmallButton
                    icon={faXmark}
                    onClick={remove}
                    remove={true}/>
            </div>
        </div>
    );
};

export default IncomeItem;
import { Fragment, useState, useEffect } from "react";
import ReactModal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark, faTrash, faSave } from "@fortawesome/free-solid-svg-icons";

import ProjectItem from "../../elements/projectItem/projectItem";
import CustomButton from "../../elements/customButton/customButton";
import DropDown from "../../elements/dropDown/dropDown";
import CustomTextInput from "../../elements/customTextInput/customTextInput";

import projectService from "../../services/projectService";

const ProjectsScreen = () => {

    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(0);
    const [mode, setMode] = useState(0);

    const [selected, setSelected] = useState(0);
    const [selectedProject, setSelectedProject] = useState();
    const [name, setName] = useState("");
    const [amount, setAmount] = useState(0);
    const [objective, setObjective] = useState(0);

    const [projects, setProjects] = useState([]);

    const recursiveTimes = [
        {
            title: "Quotidien",
            value: 0
        },
        {
            title: "Hebdomadaire",
            value: 1
        },
        {
            title: "Mensuel",
            value: 2
        },
        {
            title: "Annuel",
            value: 3
        }
    ];

    const getProjects = () => {
        projectService.GetAllProjects()
        .then((result) => {
            setProjects(result);
        });
    };

    const addProject = () => {
        const amountToSend = amount;
        projectService.AddProject({
            id: 0,
            budgetId: 0,
            title: name,
            objective: objective,
            amount: amountToSend,
            total: amountToSend,
            recursiveType: selected
        }).then((success) => {
            if (success){
                getProjects();
                setOpen(false);
            }
        });
    };

    const updateProject = () => {
        let projectToUpdate = selectedProject;
        projectToUpdate.title = name;
        projectToUpdate.amount = amount;
        projectToUpdate.objective = objective;
        projectToUpdate.recursiveType = selected;
        projectService.UpdateProject(projectToUpdate)
        .then((success) => {
            if (success){
                getProjects();
                setOpen(false);
            }
        });
    };

    const archiveProject = () => {
        projectService.ArchiveProject(selectedProject.id)
        .then((success) => {
            if (success){
                getProjects();
                setOpen(false);
            }
        });
    };

    useEffect(()=>{
        getProjects();
    },[]);

    return (
        <Fragment>
            <ReactModal
                isOpen={open}
                onRequestClose={()=>{setOpen(false)}}
                className="modal-base"
                overlayClassName="modal-overlay">
                <div>
                    <div className="modal-close">
                        <i onClick={()=>{setOpen(false)}}>
                            <FontAwesomeIcon icon={faXmark}/>
                        </i>
                    </div>
                    {
                        modal === 0 ?
                        <div className="project-modal">
                            <CustomTextInput
                                value={name}
                                setValue={setName}
                                placeholder="Projet"/>
                            <div>
                                <div className="project-modal-titled-element">
                                    <p>Montant alloué</p>
                                    <div className="project-amount-box">
                                        <CustomTextInput
                                            type="number"
                                            value={amount}
                                            setValue={setAmount}/>
                                        <p>$</p>
                                    </div>
                                </div>
                                <div className="project-modal-titled-element">
                                    <p>Récurrence</p>
                                    <DropDown
                                        options={recursiveTimes}
                                        selected={selected}
                                        setSelected={setSelected}/>
                                </div>
                            </div>
                            <div className="project-modal-titled-element">
                                <p>Objectif</p>
                                <div className="project-amount-box">
                                    <CustomTextInput
                                        type="number"
                                        value={objective}
                                        setValue={setObjective}/>
                                    <p>$</p>
                                </div>
                            </div>
                            <CustomButton
                                icon={mode === 0 ? faPlus : faSave}
                                title={mode === 0 ? "Ajouter" : "Enregistrer"}
                                onClick={()=>{mode === 0 ? addProject() : updateProject()}}/>
                        </div>:
                        <div className="delete-spending-modal">
                            <p>Supprimer ?</p>
                            <div>
                                <CustomButton
                                    onClick={()=>{setOpen(false)}}
                                    icon={faXmark}
                                    title={"Annuler"}/>
                                <CustomButton
                                    onClick={()=>{archiveProject()}}
                                    icon={faTrash}
                                    title={"Oui"}
                                    remove={true}/>
                            </div>
                        </div>
                    }
                </div>
            </ReactModal>
            <div className="page-container">
                <div className="project-list">
                    <CustomButton
                        icon={faPlus}
                        title="Ajouter"
                        onClick={()=>{
                            setModal(0);
                            setMode(0);
                            setName("");
                            setSelected(0);
                            setAmount(0);
                            setObjective(0);
                            setOpen(true);
                        }}/>
                    {projects.map((project, key) => (
                        <Fragment key={key}>
                            <ProjectItem
                                data={project}
                                edit={()=>{
                                    setModal(0);
                                    setMode(1);
                                    setName(project.title);
                                    setSelected(project.recursiveType);
                                    setAmount(project.amount);
                                    setObjective(project.objective);
                                    setSelectedProject(project);
                                    setOpen(true);
                                }}
                                remove={()=>{
                                    setModal(1);
                                    setSelectedProject(project);
                                    setOpen(true);
                                }}/>
                        </Fragment>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

export default ProjectsScreen;
import http from "../../functions/network";
import { store } from "../../functions/store";

const GetAllProjects = () => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Get(`project/budget/${budgetId}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return [];
    });
};

const AddProject = (project) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    let projectToSend = project;
    projectToSend.budgetId = budgetId;
    return http.authorized.Post(`project`, projectToSend,
    {'Content-Type' : 'application/json',})
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return true;
    })
    .catch((error) => {
        return false;
    });
};

const UpdateProject = (project) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    let projectToSend = project;
    projectToSend.budgetId = budgetId;
    return http.authorized.Put(`project`, projectToSend,
    {'Content-Type' : 'application/json',})
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return true;
    })
    .catch((error) => {
        return false;
    });
};

const ArchiveProject = (id) => {
    return http.authorized.Delete(`project/${id}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return true;
    })
    .catch((error) => {
        return false;
    });
};

export { GetAllProjects, AddProject, UpdateProject, ArchiveProject };
import { configureStore } from "@reduxjs/toolkit";

import authSlice from './reducers/auth.js';
import categoriesSlice from './reducers/categories.js';

export const store = configureStore({
    reducer: {
        auth: authSlice,
        categories: categoriesSlice
    }
});
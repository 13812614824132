import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SmallButton = ({icon, onClick, remove=false}) => {
    return (
        <i onClick={onClick}
           className={remove ? "small-button-remove" : "small-button"}>
            <FontAwesomeIcon icon={icon}/>
        </i>
    );
};

export default SmallButton;
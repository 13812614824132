import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: "",
    userId: 0,
    budgetId: 0
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        setBudgetId: (state, action) => {
            state.budgetId = action.payload;
        }
    }
});

export const {
    setToken,
    setUserId,
    setBudgetId
} = authSlice.actions;

export default authSlice.reducer;
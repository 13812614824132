import { store } from "../../store";
import BaseURL from "../../baseURL";

const Get = (uri, body=undefined, headers=undefined) => {
    return request(uri, "GET", body, headers);
};

const Post = (uri, body=undefined, headers=undefined) => {
    return request(uri, "POST", body, headers);
};

const Put = (uri, body=undefined, headers=undefined) => {
    return request(uri, "PUT", body, headers);
};

const Delete = (uri, body=undefined, headers=undefined) => {
    return request(uri, "DELETE", body, headers);
};

const request = (uri, method, body, headers) => {
    const state = store.getState();
    const token = state.auth.token;
    return fetch(`${BaseURL()}/${uri}`, {
        method: method,
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            'Authorization': token,
            ...(body && headers ? headers : {})
        }
    });
};

export { Get, Post, Put, Delete };
import { toMoney } from "../../functions/toMoney";

const ExpenseCategory = ({even, data}) => {
    return (
        <div className={`expense-category ${data.isParent ? 'expense-category-parent' : ''}`}
             style={data.isParent ? {} : {backgroundColor: even ? '#ddd' : '#eee'}}>
            <p>{data.category}</p>
            <p>{toMoney(data.total)}</p>
        </div>
    );
};

export default ExpenseCategory;
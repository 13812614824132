import CustomButton from "../customButton/customButton";
import DropDown from "../dropDown/dropDown";

import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const TimePeriodPicker = ({options, year, setYear, month, setMonth}) => {

    const setToCurrentPeriod = () => {
        const currentDate = new Date();
        setYear(currentDate.getFullYear());
        setMonth(currentDate.getMonth() + 1);
    };

    return (
        <div className="time-period-picker">
            <DropDown
                options={options.months}
                selected={month}
                setSelected={setMonth}/>
            <DropDown
                options={options.years}
                selected={year}
                setSelected={setYear}/>
            <CustomButton
                title="En cours"
                icon={faCalendar}
                onClick={()=>{setToCurrentPeriod();}}/>
        </div>
    );
};

export default TimePeriodPicker;
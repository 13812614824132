import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CustomCheckbox = ({title, checked, setChecked}) => {
    return (
        <div
            className="custom-checkbox"
            onClick={()=>{setChecked(!checked)}}>
            <div>
                {checked && <i><FontAwesomeIcon icon={faCheck}/></i>}
            </div>
            <p>{title}</p>
        </div>
    );
};

export default CustomCheckbox;
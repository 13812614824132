import http from "../../functions/network";
import { store } from "../../functions/store";

const GetAllByBudgetIdAndMonth = (date) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Get(`money-entry/income/budget/${budgetId}/${date}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return [];
    });
};

const AddIncomeToBudget = (income) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    const userId = state.auth.userId;
    let incomeToSend = income;
    incomeToSend.budgetId = budgetId;
    incomeToSend.userId = userId;
    return http.authorized.Post(`money-entry/income`, incomeToSend,
    {'Content-Type' : 'application/json',})
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return true;
    })
    .catch((error) => {
        return false;
    });
};

const UpdateIncomeOfBudget = (income) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    let incomeToSend = income;
    incomeToSend.budgetId = budgetId;
    return http.authorized.Put(`money-entry/income`, incomeToSend,
    {'Content-Type' : 'application/json',})
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return true;
    })
    .catch((error) => {
        return false;
    });
};

const RemoveIncomeFromBudget = (id) => {
    return http.authorized.Delete(`money-entry/${id}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return true;
    })
    .catch((error) => {
        return false;
    });
};

export { GetAllByBudgetIdAndMonth, AddIncomeToBudget, UpdateIncomeOfBudget, RemoveIncomeFromBudget };
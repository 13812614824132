import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const DropDown = ({options, selected=0, setSelected}) => {

    const [open, setOpen] = useState(false);

    return (
        <div className="drop-down"
            tabIndex={0}
            onBlur={()=>{setOpen(false);}}>
            <div
                style={{borderBottomLeftRadius: open ? '0px' : '10px',
                        borderBottomRightRadius: open ? '0px' : '10px'}}
                className="drop-down-control"
                onClick={()=>{setOpen(!open);}}>
                <p>{options?.find(o => o.value === selected)?.title ?? ""}</p>
                <i><FontAwesomeIcon icon={open ? faChevronUp : faChevronDown}/></i>
            </div>
            <div className="drop-down-list">
                {options.map((entry, key) => (
                    <div
                        className="drop-down-element"
                        style={{display: open ? 'block' : 'none',
                                backgroundColor: key % 2 === 0 ? '#ddd' : '#eee'}}
                        key={key}
                        onClick={()=>
                            {
                                setOpen(false);
                                setSelected(entry?.value ?? 0);
                            }}>
                        <p>{entry.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DropDown;
import http from "../../functions/network";
import { store } from "../../functions/store";
import { setCategories } from "../../functions/store/reducers/categories";

const GetAllByBudgetId = () => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Get(`category/all/budget/${budgetId}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return [];
    });
};

const GetAllParentlessByBudgetId = () => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Get(`category/all/budget/${budgetId}/brief/parentless`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return [];
    });
};

const GetAllNotParentByBudgetId = () => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Get(`category/all/budget/${budgetId}/brief`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        store.dispatch(setCategories(data));
    })
    .catch((error) => {
        return;
    });
};

const AddCategoryToBudget = (category) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    category.budgetId = budgetId;
    return http.authorized.Post(`category`, category,
    {'Content-Type' : 'application/json',})
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return null;
    });
};

const UpdateCategory = (newCategory) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    newCategory.budgetId = budgetId;
    return http.authorized.Put(`category`, newCategory,
    {'Content-Type' : 'application/json',})
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return null;
    });
};

const ArchiveCategory = (categoryId) => {
    const state = store.getState();
    const budgetId = state.auth.budgetId;
    return http.authorized.Delete(`category/budget/${budgetId}/${categoryId}`)
    .then((result) => {
        if(!result.ok){
            throw new Error();
        }
        return result.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        return null;
    });
};

export { 
    GetAllByBudgetId,
    GetAllParentlessByBudgetId,
    GetAllNotParentByBudgetId,
    AddCategoryToBudget,
    UpdateCategory,
    ArchiveCategory };